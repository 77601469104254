import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { logout_func2 } from "../Const/logoutCommon";
import axios from "axios";
import { BASE_URL } from "../Const/Url";
import { useEffect } from "react";
import Loader from "../CustomCommons/Loader";
import { Form, InputGroup } from "react-bootstrap";

const ViewOffer = () => {
  const history = useHistory();
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [offer, setOffer] = useState({});
  const getOffer = () => {
    const url = `${BASE_URL}api/v1/promotions/admin/offer/${id}`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOffer(result);
        setLoading(false);
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          logout_func2(setOnlogoutHide, history);
        }
      });
  };
  useEffect(() => {
    getOffer();
  }, []);
  if (loading) {
    return <Loader />;
  }
  var options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>View Offer</h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            // borderBottom: " 0.5px solid #E0E0E0",
            // borderRight: " 0.5px solid #E0E0E0",
            // borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 col-md-6">
            <p>Offer Name </p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={offer?.name}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <p>Offer ID</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={offer?.offer_id}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <p>Offer Start Date</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={new Date(offer.start_date).toLocaleDateString(
                  "en-US",
                  options
                )}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 col-md-6">
            <p>Offer End Date</p>
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ backgroundColor: "#FAFAFA" }}
                placeholder="Select a date"
                name="expiry_date"
                value={new Date(offer.expiry_date).toLocaleDateString(
                  "en-US",
                  options
                )}
                disabled
              />
            </InputGroup>
          </div>
          <div className="col-12 row justify-content-between">
            <h5 className="my-3">Offer Products</h5>
            {offer?.product_details &&
              offer?.product_details?.map((data) => (
                <>
                  <div key={data?.id} className="col-md-6  col-12 ">
                    <div className="card mb-3" style={{ maxWidth: "540px" }}>
                      <div className="row no-gutters">
                        <div className="col-md-4">
                          <img
                            src={data?.thumb_url}
                            className="card-img m-1 img-fluid"
                            style={{objectFit:'contain',width:'100%'}}
                            alt="..."
                          />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <h5 className="card-title">{data?.name?.length > 15 ? `${data?.name.slice(0,15)}...` : `${data?.name}`}</h5>
                            <p className="card-text">
                              Discount :{" "}
                              {offer?.discount_type === 1
                                ? `${offer?.discount_value} %`
                                : `${offer?.discount_value} Tk`}
                            </p>
                            <p className="card-text">Stocks : {data?.stock}</p>
                            <p className="card-text">
                              <small className="text-muted">
                                Last updated{" "}
                                {new Date(data.updated_at).toLocaleDateString(
                                  "en-US",
                                  options
                                )}
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOffer;
